import React, { useState, useEffect } from "react";
import { Scatter } from "react-chartjs-2";
import { Chart as CharJS } from "chart.js/auto";
import { useAlternative } from "../../context/AlternativeContext";
import { useCriteria } from "../../context/CriteriaContext";
import {
  useWeightedScore,
  useWeightedScoreUpdate,
} from "../../context/EvaluatedAlternativeContext";

const ScatterPlot = ({ chartData, labels }) => {
  const allCriterias = useCriteria();
  const allAlternatives = useAlternative();
  const allWeightedScores = useWeightedScore();

  //queue to hold alternatives to compare
  const [plotData, setPlotData] = useState([]);
  const [plotLabel, setPlotLabel] = useState([]);
  const [chartDataSet, setChartDataSet] = useState([]);
  const setCompare = (id, name) => {
    let temp = plotData;
    let tempLabel = plotLabel;
    //check to see if array length is 2
    //if array length === 2 pop the queue from the front and insert the new alternative
    if (plotData.length === 2) {
      temp.shift();
      temp.push(id);
      tempLabel.shift();
      tempLabel.push(name);
    } else {
      //if array length < 2 then just add the new alternative to the queue
      temp.push(id);
      tempLabel.push(name);
    }
    setPlotData([...temp]);
    setPlotLabel([...tempLabel]);
  };

  useEffect(() => {
    let tempWeight = allWeightedScores;
    let tempAlternatives = allAlternatives;
    let temp = plotData;

    const tempPlot = [];
    for (const t of temp) {
      tempPlot.push({
        data: tempWeight.filter((w) => {
          return w.critId === t;
        }),
      });
    }

    const plotDataSet = [];
    const barColors = [
      "#70e0de",
      "#ebd363",
      "#f97766",
      "#14b0f9",
      "#6101e8",
      "#a6a4d3",
      "#d48fa6",
      "#3bcc1d",
      "#106f34",
      "#b16d31",
    ];

    if (tempPlot.length > 1) {
      for (const [i, alt] of tempAlternatives.entries()) {
        let xCoord = 0;
        let yCoord = 0;
        for (const el of tempPlot[1].data) {
          if (el.altId === alt.id) {
            xCoord = el.weight;
          }
        }
        for (const el of tempPlot[0].data) {
          if (el.altId === alt.id) {
            yCoord = el.weight;
          }
        }
        plotDataSet.push({
          label: alt.name,
          data: [{ x: xCoord, y: yCoord }],
          pointRadius: 8,
          backgroundColor: barColors[i],
        });
      }
    }

    setChartDataSet(plotDataSet);
  }, [plotData, allWeightedScores]);

  useEffect(() => {
    console.log("allwehgithed");
  }, [allWeightedScores]);

  return (
    <div className="w-6/12  rounded-lg shadow-xl relative mt-12 bg-white">
      <div className="w-full aboslute top-0 bg-wc-green h-12 rounded-t-lg flex justify-center items-center text-lg">
        <h2>Criteria Comparison</h2>
      </div>
      <div className="alt-select w-full flex justify-center p-4 gap-4 text-black dark:text-white dark:bg-gray-500">
        <h2>Select two alternatives to compare</h2>
        {allCriterias &&
          allCriterias.map((crit) => {
            return (
              <div>
                <label>{crit.name}</label>
                <input
                  type="radio"
                  checked={plotData.includes(crit.id)}
                  value={crit.id}
                  onChange={(e) => {
                    setCompare(crit.id, crit.name);
                    console.log("plotData", plotData);
                  }}
                />
              </div>
            );
          })}
      </div>

      <Scatter
        className="dark:bg-gray-500"
        data={{
          datasets: chartDataSet,
        }}
        options={{
          layout: { padding: 12 },
          scales: {
            y: {
              beginAtZero: true,
              max: 1.4,
              stepSize: 0.1,
              title: {
                display: true,
                text: plotLabel[0],
              },
            },
            x: {
              beginAtZero: true,
              max: 1.4,
              stepSize: 0.1,
              title: {
                display: true,
                text: plotLabel[1],
              },
            },
          },
          plugins: {
            legend: {
              labels: { boxWidth: 10 },
              align: "middle",
              position: "right",
            },
          },
          responsive: true,
        }}
      />
    </div>
  );
};

export default ScatterPlot;
